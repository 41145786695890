import React from "react"
import {graphql} from 'gatsby'
import Card from 'components/blog/card';
import Layout from 'components/layout/internal';
import Link from 'shared/link'
import MastheadInterior from 'components/masthead/interior'
import SEO from 'shared/seo'

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
	window.document && window.document.createElement)
);

const BlogTopicAll = ({data, pageContext}) => {
	// pageContext.header = 'home_hero.png';

	const topicID = pageContext.id;
	var contentPages = data.allContentPage.edges;
	var title = 'Blog';
	var description = '';

	var page = contentPages.filter(page => (page.node.uri === '/blog/' || page.node.uri === '/en/blog/'));
	if (page.length) {
		page = page[0].node;
	}

	// Looping through topics to get title
	data.allBlogTopic.edges.forEach(cf =>{
		if(cf.node.id === topicID) {
			title = cf.node.name;
			description = cf.node.description;
		}
	})

	let allData = data.allBlogPost.edges;

	return (
		<>
		<Layout>
			<MastheadInterior title={title} intro={description} />
			<hr />
			<section className="grid-container">
				<div className="grid-x medium-up-3">
					{allData.map((card, index) => (
						<div className="cell">
							<Card key={index} {...card.node} />
						</div>
					))}
				</div>
			</section>
		</Layout>
		<SEO title="Read the Heritage Medical Blog" />
		</>
	)
}

export default BlogTopicAll

export const query = graphql`
	query ($id: String, $uri: String) {
	  allBlogPost(sort: {order: DESC, fields: showDate}, filter: {Topic: {elemMatch: {object: {id: {eq: $id}}}}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	      	_model
	        id
	        photo
	        title
	        permalink
	        uri
	        intro
	        Topic {
	          object {
	          	id
	          	name
	          	permalink
	          }
	        }
	      }
	    }
	  }
	  allBlogTopic(sort: {order: ASC, fields: name}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	        description
	      }
	    }
	  }
	  allContentPage(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
			uri
			pageTitle
	      }
	    }
	  }
	}
`